.header {
    background: $bg-color-main;

    &__navigation {
        margin: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        margin: 0;

        flex-shrink: 0;
    }

    &__svg {
        width: 160px;
        height: 90px;

        & path {
            fill: $white;
        }
    }

    &__svg:hover {
        & path {
            fill: $blue;
        }
    }

    &__menu-list {
        list-style: none;

        display: flex;
        padding: 0;

        justify-content: flex-end;
        align-items: center;

        //width: 800px;

        & li {
            margin: 0 0 0 1rem;

            & a {
                display: block;
                padding: 0.5rem 1rem;

                text-transform: uppercase;
                color: $white;

                &:hover {
                    color: $blue;

                    text-decoration: none;
                }
            }
        }
    }

    &__button {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .header {
        &__svg {
            width: 96px;
            height: 54px;
        }

        &__button {
            display: block;
            z-index: 5;
        }

        &__menu-list {
            position: absolute;
            top: -100vh;
            right: 0%;

            margin: 0;
            padding: 105px 32px 84px;
            display: block;

            width: 185px;

            background: $white;
            border: 1px solid $gray;
            border-radius: 0 0 0 8px;

            transition: top 0.3s ease-in;
            z-index: 5;

            & li {
                margin: 0 0 1.5rem;

                & a {
                    padding: 0;

                    text-align: right;
                    color: $black;
                }
            }
        }

        &__menu-list.active {
            top: 0%;
        }
    }
}
