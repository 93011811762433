.features {
    padding: 80px 0;

    color: $white;

    @include bg-grid;

    &__header {
        margin: 0 0 2rem;
        text-align: center;
        text-transform: uppercase;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        gap: 30px;

        & ol {
            display: flex;
            flex-wrap: wrap;

            justify-content: space-between;

            padding: 0;

            & li {
                position: relative;

                padding: 160px 0 0;

                flex-shrink: 0;

                width: 20%;
            }

            & li:before {
                position: absolute;
                content: "";

                top: 0;
                left: 0;
                right: 0;

                margin: 0 auto;

                width: 160px;
                height: 160px;
            }
            & li:nth-of-type(1):before {
                background: url('../img/phone.png') no-repeat;
            }
            & li:nth-of-type(2):before {
                background: url('../img/direction.png') no-repeat;
            }
            & li:nth-of-type(3):before {
                background: url('../img/document.png') no-repeat;
            }
            & li:nth-of-type(4):before {
                background: url('../img/receipt.png') no-repeat;
            }
        }
    }

    &__column {
        flex-shrink: 0;

        width: 50%;

        & ul {
            margin: 0 0 1rem;
            padding: 0;

            list-style: none;
        }

        & ul li {
            position: relative;
            margin: 0 0 1.5rem;

            &:before {
               @include square;

                top: 10px;
                left: -13px;

                border: 2px solid $white;
            }
        }

        & strong {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.6875rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .features {
        padding: 60px 0 20px;

        &__content {
            flex-wrap: wrap;
            gap: 20px;

            & ol {
                & li {
                    padding: 140px 0 0;

                    margin: 0 0 32px;

                    width: 45%;
                }

                & li:before {
                    width: 120px;
                    height: 120px;
                }
            }
        }

        &__column {
            width: 100%;

            & ul {
                margin: 0;
                padding: 0 0 0 15px;

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 30px;

                & li {
                    margin: 0;

                    flex-shrink: 0;

                    width: 45%;

                    line-height: 1.2rem;

                    & strong {
                        font-size: 1rem;
                        line-height: 1.2rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .features {
        padding: 60px 0 0;

        &__content {
            flex-wrap: wrap;
            gap: 20px;

            & ol {
                & li {
                    padding: 140px 0 0;

                    margin: 0 0 32px;

                    width: 100%;
                }

                & li:before {
                    width: 120px;
                    height: 120px;
                }
            }
        }

        &__column {
            & ul {
                & li {
                    width: 100%;
                }
            }
        }
    }
}
