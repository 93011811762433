.promo {
    position: relative;

    display: block;

    &:before {
        position: absolute;
        content: "";
        background: url('../img/bg-towing-big-good.jpg') no-repeat top center;
        background-size: cover;

        width: 100%;
        height: 100%;

        opacity: 0.5;

        z-index: 1;
    }

    &__block {
        position: relative;

        padding: 230px 0 150px;
        width: 700px;

        z-index: 3;
    }

    &__header {
        margin: 0 0 3rem;
        color: $black;
    }

    &__content {
        color: $black;

        & p {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

@media screen and (max-width: 767px) {
    .promo {
        padding: 0;

        &__block {
            padding: 80px 0;

            width: 100%;
        }
    }
}
