h1, h2, h3 {
    //margin: 1.5rem 0 1rem;
}

h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
    font-weight: 500;
}

h2 {
    font-size: 2rem;
    line-height: 3.5rem;
    font-weight: 500;
}

h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
}

ul, ol {
    margin: 1rem 0;
    padding-left: 1.5rem;
}

ul ul, ul ol, ol ul, ol ol {
    margin: 0;
}

ul {
    list-style-image: url("../img/square.jpg");
}

/*
ul {
  list-style: disc;
}

li {
  font-size: 1rem;
  line-height: $line-height;
}

ol li::marker,
ul li::marker {
  color: $font-color-secondary;
}

ul li::marker {
  font-size: 1.5rem;
}
*/

a {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $blue;

    text-decoration: none;
}

a:hover {
    color: $blue2;

    text-decoration: underline;
}

p {
    font-size: 1rem;
    line-height: $line-height;
    margin: 0 0 1rem;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

/*
table {
  margin: 0 0 20px;

  width: 100%;

  font-size: 90%;

  table-layout: fixed;
  border-collapse: collapse;
  border-radius: 8px;
}

table th {
  padding: 15px 10px;

  width: 20%;

  color: $font-color-secondary;
  font-weight: normal;

  background-color: $bg-color-third;
  border: 1px solid $border-color-third;
}

table td {
  padding: 5px 10px;

  text-align: left;
  vertical-align: top;

  background: $white;
  border: 1px solid $border-color-third;
}

blockquote {
  margin: 20px 0;
  padding: 15px;

  background: rgba(236, 7, 4, .12549019607843137);
  border-left: 5px solid $carrot;
}

@media (max-width: 767px) {
  table {
    font-size: 100%;
  }

  table tr {
    display: flex;
    flex-direction: column;
  }

  table th {
    width: 100%;
  }
}
*/
@media screen and (max-width: 992px) {
    h1 {
        font-size: 3rem;
        line-height: 3rem;
    }

    h2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 2rem;
        line-height: 2rem;
    }

    h2 {
        font-size: 1.6rem;
        line-height: 1.8rem;
    }
    h3 {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
}
