@mixin square {
    position: absolute;
    content: "";

    width: 8px;
    height: 8px;
}

@mixin bg-grid {
    background:
        linear-gradient($gray, transparent 1px),
        linear-gradient(90deg, $gray, transparent 1px),
        $bg-color-main;

    background-size: 250px 100vh;
    background-position: center center;
}
