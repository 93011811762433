.copyright {
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.1);

    &__content {
        padding: 1.25rem;
        color: $white;
        text-align: center;

        & p {
            margin: 0;
        }

        & a {
            color: $white;
        }
    }
}
