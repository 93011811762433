.btn {
    display: inline-block;
    padding: 0.75rem 3.5rem;

    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: $white;

    background-color: $blue;
    border: none;

    &:hover {
        color: $white;
        background-color: $blue2;
        text-decoration: none;

        cursor: pointer;
    }
}
