.companies {
    &__list {
        margin: 0 0 50px;
        padding: 0;

        /*
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
         */

        list-style: none;
    }

    &-item {
        background: #f1f1f1;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 7%);

        &:hover {
            box-shadow: 0 1px 3px rgb(0 0 0 / 20%);

            //cursor: pointer;
        }
    }

    &__header {
        display: block;
        margin: 0 0 0 16px;
    }

    &-item a {
        padding: 7px 5px;

        display: block;

        font-size: 16px;
        text-align: left;
        text-decoration: none;
        color: $black;

        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }

    &__btn {
        margin: 0 auto;
        display: block;
    }
}
