*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;

  font-size: 1rem;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $black;

  font-family: $font-family, sans-serif;
  font-style: normal;

  background: $white;
}

body.lock {
  overflow: hidden;
}

header,
footer {
  flex: 0 0 auto;
}

main {
  overflow: hidden;
  flex: 1 0 auto;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  margin: 0 auto;
  padding: 0 15px;

  width: 100%;
  max-width: 1440px;
  //max-width: 1024px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

@media screen and (max-width: 767px) {
    .container {
        padding: 0 24px;
    }
}
