.view-id-cities_list_for_current_company > .container {
    padding: 0;
}
.company-cities {
    &__list {
        margin: 0;
        padding: 0;

        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        list-style: none;
    }

    &--list-item a {
        display: block;
        padding: 7px 5px;

        text-align: center;
        color: $black;

        background: #f1f1f1;
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 7%);

        &:hover {
            color: $blue;
            text-decoration: none;
            border-radius: 2px;
            box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
        }
    }
}

@media (max-width: 992px) {
    .company-cities {
        margin: 30px 0;

        &__list {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media (max-width: 767px) {
    .company-cities {
        margin: 20px 0;

        &__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (max-width: 640px) {
    .company-cities {
        margin: 20px 0;

        &__list {
            grid-template-columns: repeat(2, 1fr);
        }

        &--list-item a {
            font-size: 14px;
            line-height: 27px;
        }
    }
}
