.swiper {
    position: relative;
    margin: 0 auto;
    padding: 0;

    width: 100%;
    height: auto;

    list-style: none;

    overflow: hidden;
    z-index: 1
}

.swiper-autoheight, .swiper-autoheight .swiper-slide {
    height: auto;
}

.swiper-wrapper {
    position: relative;
    padding: 0;

    display: flex;
    width: 100%;
    height: 100%;

    z-index: 1;
}
.swiper-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform,height;
}
.swiper-android .swiper-slide, .swiper-wrapper {
    transform: translate3d(0px,0,0);
}

.swiper-pointer-events {
    touch-action: pan-y
}

.swiper-pointer-events.swiper-vertical {
    touch-action: pan-x
}

.swiper-slide {
    position: relative;
    padding: 20px;

    display: flex;
    flex-shrink: 0;

    width: 920px;
    height: 100%;

    border: 1px solid #dcdcdc;
    border-radius: 7px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 7%);

    transition-property: transform;
}

.swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper__img {
    margin: 0 40px 0 0;
    flex-shrink: 0;

    max-height: 471px;
}

.swiper__block {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.swiper__nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.swiper__title a {
    font-size: 2rem;
    line-height: 2rem;

    color: $black;
}

.swiper__title a:hover {
    color: $blue;
}

.swiper__link {
    margin: 0 48px 0 0;
}

.swiper__btn {
    width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid $gray;

    cursor: pointer;
}

.swiper-button-prev {
    border-right: none;
}

@media screen and (max-width: 992px) {
    .swiper {
        .swiper-slide {
            width: 100%;
        }

        &__img a div img {
            width: 200px;
        }
    }
}

@media screen and (max-width: 767px) {
    .swiper {
        height: auto;

        .swiper__img {
            display: none;
        }

        .swiper-slide {
            width: 100%;
        }
    }
}



