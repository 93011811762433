.button--burger {
    position: relative;
    display: none;

    margin: 0 0 0 0;
    width: 24px;
    height: 24px;

    background: transparent;
    border: none;
    cursor: pointer;

    z-index: 5;
}

.button--burger span,
.button--burger::before,
.button--burger::after {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 18px;
    height: 2px;

    transition: all 0.3s ease 0s;
}

.button--burger span {
    top: 11px;

    background: $white;
}

.button--burger::before,
.button--burger::after {
    content: '';

    background: $white;
}

.button--burger::before {
    top: 5px;
}

.button--burger::after {
    bottom: 5px;
}

.button--burger.active::before {
    top: 11px;

    background: $black;

    transform: rotate(45deg);
}

.button--burger.active span {
    transform: scale(0);
}

.button--burger.active::after {
    bottom: 11px;

    background: $black;

    transform: rotate(-45deg);
}

.overlay {
    display: none;
}

.overlay.active {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

@media (max-width: 767px) {
    .button--burger {
        display: block;
    }
}
