.accordeon {
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item-btn:hover {
        cursor: pointer;
    }

    &__btn {
        margin: 0 24px 0 0;
        display: block;
        position: relative;
        content: '';

        width: 20px;
        height: 20px;

        border: 1px solid $blue;
        border-radius: 50%;

        pointer-events: none;
        //cursor: pointer;
    }

    &__btn::before,
    &__btn::after {
        position: absolute;
        content: '';
        width: 8px;
        height: 1px;
        background: $blue;

        transition: transform var(--accordeon-time) ease-out;
    }

    &__btn::before {
        top: 8px;
        left: 5px;
        transform: rotate(0deg);
    }

    &__btn::after {
        top: 8px;
        left: 5px;
        transform: rotate(90deg);
    }

    &__btn--open &__btn::before {
        transform: rotate(225deg);
    }

    &__btn--open &__btn::after {
        transform: rotate(315deg);
    }

    &__content {
        box-sizing: content-box;
        max-height: 0;
        padding: 0;
        opacity: 0;
        overflow: hidden;
        will-change: max-height;
        transition: all var(--accordeon-time) ease-out;
    }

    &__content--active {
        padding: 15px;

        opacity: 1;
    }
}

@media (max-width: 740px) {
    .accordeon {
        &__btn {
            top: 2px;
        }
    }
}
