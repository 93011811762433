.content-block {
    padding: 20px 0;

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        gap: 15px;
    }

    &__img {
        flex-shrink: 0;
    }

    & .articles-list {
        margin: 0 0 1rem;
        padding: 0;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 15px;

        list-style: none;
    }

    & .articles-item {
        flex-shrink: 0;

        width: 32%;

        border: 1px solid #dcdcdc;
    }

    & &__article {
        padding: 20px;

        height: 100%;

        & h2 a {
            font-size: 2rem;
            line-height: 3.5rem;
            font-weight: 500;
        }
    }

    & .services-list {
        padding: 0;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        list-style: none;
    }

    & .services-item {
        margin: 0 0 1rem;

        & h2 a{
            display: block;
            text-align: center;

            font-size: 2rem;
            line-height: 2rem;
            color: $black;
        }

        & article {
            padding: 1rem;

            height: 100%;
        }

        & .service__btn {
            margin: 0 auto;
        }
    }

    & &__service {
        border: 1px solid #dcdcdc;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 7%);
    }

    & .addresses-list {
        padding: 0;
        list-style: none;
    }

    & .addresses-list li {
        margin: 0 0 1rem;
    }

    &__address {
        padding: 20px;

        display: flex;
        align-items: flex-start;
        gap: 15px;

        border: 1px solid #dcdcdc;
        border-radius: 5px;
    }

    &__iframe {
        width: 600px;
        flex-shrink: 0;
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
    }


    &__header a {
        font-size: 1.5rem;
    }


    &__nav {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    & .pager {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;

        list-style: none;
    }

    & .pager .pager-first,
    & .pager .pager-previous,
    & .pager .pager-item,
    & .pager .pager-current,
    & .pager .pager-next,
    & .pager .pager-last {
        margin: 0;
        padding: 0;

        width: 40px;
        height: 40px;

        background: #f1f1f1;
        border: 1px solid #dcdcdc;
    }

    & .pager .pager-current,
    & .pager a {
        padding: 7px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .pager a:hover{
        text-decoration: none;
    }

}

@media screen and (max-width: 992px) {
    .content-block {
        & .articles-item {
            width: 48.5%;
        }

        &__iframe {
            width: 400px;
        }
    }
}


@media screen and (max-width: 767px) {
    .content-block {
        &__container {
            flex-direction: column;
        }

        &__img {
            align-self: center;
        }

        & .articles-item {
            width: 100%;
        }

        & &__text a {
            display: block;
        }

        & &__text img{
            margin: 0 auto;
            display: block;
        }

        &__nav {
            flex-direction: column;
        }

        &__address {
            flex-wrap: wrap;
        }

        &__iframe {
            height: 300px;
            width: 100%;

            order: 1;
        }
    }
}
