.contacts {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    &__form{
        flex-shrink: 0;

        width: 50%;
    }

    & .webform-client-form > div {
        display: flex;
        flex-wrap: wrap;

        justify-content: space-between;
    }

    & .form-item {
        width: 48%;
    }

    & .webform-component-textarea {
        width: 100%;
    }

    & .form-item input,
    & .form-textarea-wrapper textarea,
    & .form-select {
        padding: 8px;

        width: 100%;
        height: 46px;

        line-height: 1.2rem;

        border: 2px solid #dcdcdc;
        border-radius: 3px;
    }

    & .form-textarea-wrapper textarea {
        height: auto;
    }

    & .form-submit {
        display: inline-block;
        padding: 0.75rem 3.5rem;

        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        color: $white;

        background-color: $blue;
        border: none;

        &:hover {
            color: $white;
            background-color: $blue2;
            text-decoration: none;

            cursor: pointer;
        }
    }
}

@media (max-width: 767px) {
    .contacts {
        flex-wrap: wrap;

        &__text {
            order: 1;
            width: 100%;
        }

        &__form {
            order: 2;
            width: 100%;
        }
    }
}

@media (max-width: 480px) {
    .contacts {
        & .form-item {
            width: 100%;
        }
    }
}
