// ACTIVE COLORS:
$white: #fff;
$black: #0D0D14;
$blue: #0C3DE2;
$blue2: #082b9e;
$gray: #5B5B60;
$silver: #f7f3f1;

// BACKGROUNDS COLORS
$bg-color-main: linear-gradient(0deg, rgba(12, 61, 226, 0.1), rgba(12, 61, 226, 0.1)), $black;

// FONT
$font-family: 'Oswald';
$font-size: 16px;
$line-height: 150%;
$font-weight: 400;
$font-color-main: $black;
$font-color-secondary: $white;
